<template>
    <base-form models-name="eventi"
               :tabs="tabs"
               :new-model="newModel"
               :is-resouces-loading="isLoadingWorkspace || isLingueLoading || isLoadingCliente || isLoadingTipologia || isLoadingCitta"
               @endLoadModel="endLoadModel"
               @onSave="onSave"
    >

        <template #info="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col v-show="false" class="px-0" cols="9">
                    <base-select-lingue @finish="isLingueLoading = false; panelTxt = lingue.findIndex(l=>l.IS_DEFAULT);"
                    ></base-select-lingue>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.DISATTIVAZIONE_MANUALE"
                                       :items="[ {label: 'ATTIVO', value: false}, {label: 'DISATTIVATO', value: true} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Stato"
                                       placeholder="Seleziona se abilitare o disabilitare"
                                       hint="Decidi se abilitare o disabilitare manualmente l'evento"
                                       :disabled="!!isGlobalLoading || !$store.state.authModule.utente.CAN_ENABLE_EVENTO"
                                       prepend-icon="fa-toggle-on"
                    ></base-select-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.IS_SUGGERITO"
                                       :items="[ {label: 'AGGIUNTO', value: true}, {label: 'TOLTO', value: false} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Tesoro Nascosto"
                                       placeholder="Seleziona se aggiungere o togliere dai testori nascosti"
                                       hint="Decidi se aggiungere o togliere l'evento dai testori nascosti"
                                       :disabled="!!isGlobalLoading || !$store.state.authModule.utente.CAN_SUGGERITO_EVENTO"
                                       prepend-icon="fa-gem"
                    ></base-select-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.IS_INVISIBILE"
                                       :items="[ {label: 'NASCOSTO', value: true}, {label: 'MOSTRATO', value: false} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Nascosto dalla Ricerca"
                                       placeholder="Seleziona se nascondere o mostrare tra i risultati dalla ricerca"
                                       hint="Decidi se nascondere o mostrare tra i risultati dalla ricerca"
                                       :disabled="!!isGlobalLoading"
                                       prepend-icon="fa-eye"
                    ></base-select-field>
                </h-col>

                <h-col v-if="$store.getters.isSystem" class="px-0" cols="9">
                    <base-form-field-api-select models-name="workspace"
                                                v-model="model.ID_WORKSPACE_FK"
                                                item-text="NOME"
                                                item-value="ID_REC"
                                                label="Workspace"
                                                placeholder="Seleziona un workspace"
                                                hint="Il workspace è essenziale e non sarà più modificabile"
                                                :disabled="!!isGlobalLoading || !!model.ID_REC"
                                                prepend-icon="fa-laptop-house"
                                                :rules="[v => !!v || 'Il workspace è obbligatorio!']"
                                                @finish="isLoadingWorkspace = false"
                    ></base-form-field-api-select>
                </h-col>

                <h-col v-if="!$store.getters.isFromCliente"
                       v-show="!model.ID_REC || !!$store.state.apiModule.clienti.find(c=>c.ID_REC===model.ID_CLIENTE_FK)"
                       class="px-0"
                       cols="9"
                >
                    <base-form-field-api-select models-name="clienti"
                                                v-model="model.ID_CLIENTE_FK"
                                                item-text="ID_REC"
                                                item-value="ID_REC"
                                                label="Cliente"
                                                placeholder="Seleziona un cliente"
                                                hint="Il cliente da associare all'evento"
                                                :disabled="!!isGlobalLoading"
                                                prepend-icon="fa-users"
                                                :rules="[v => !!model.ID_REC || !!v || 'Il cliente è obbligatorio!']"
                                                :block-auto-start="!$store.state.authModule.utente.CAN_CLIENTE"
                                                @finish="isLoadingCliente = false;"
                    >

                        <template #item="{ item }">
                            {{ getClienteTaglie(item) }}
                        </template>

                        <template #selection="{ item }">
                            {{ getClienteTaglie(item) }}
                        </template>

                    </base-form-field-api-select>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.TAGLIA"
                                       :items="['XS', 'S', 'M', 'L', 'XL', 'XXL']"
                                       label="Taglia"
                                       placeholder="Seleziona la taglia dell'evento"
                                       hint="La taglia corrisponde al valore commerciale dell'evento"
                                       :disabled="!!isGlobalLoading"
                                       prepend-icon="fa-tshirt"
                                       :rules="[v => !!v || 'La taglia è obbligatoria!']"
                    ></base-select-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-form-field-api-select models-name="tipologie"
                                                v-model="model.ID_TIPOLOGIA_FK"
                                                item-text="ID_REC"
                                                item-value="ID_REC"
                                                label="Tipologia"
                                                placeholder="Seleziona una tipologia"
                                                hint="La tipologia indica la categoria a cui farà parte l'evento"
                                                :disabled="!!isGlobalLoading"
                                                prepend-icon="fa-boxes"
                                                :rules="[v => !!v || 'La tipologia è obbligatoria!']"
                                                disable-sort
                                                @finish="isLoadingTipologia = false;"
                                                @input="model.IS_PERCORSO = !!getTipologia(model) && getTipologia(model).IS_PERCORSO;
                                                        !model.IS_PERCORSO && !model.galleria.find(g=>g.IS_COVER) ? model.galleria.unshift({NOME: 'COVER', IS_VIDEO: false, IS_COVER: true, IS_LAVORAZIONE: false, IS_ON_TOP: false}) : null"
                    >

                        <template #item="{ item }">
                            <v-icon v-if="!item.IS_PERCORSO">fa-calendar-alt</v-icon>
                            <v-icon v-else>fa-route</v-icon>
                            <span style="margin-left: 8px;">{{ getNome(item) }}</span>
                        </template>

                        <template #selection="{ item }">
                            <v-icon v-if="!item.IS_PERCORSO">fa-calendar-alt</v-icon>
                            <v-icon v-else>fa-route</v-icon>
                            <span style="margin-left: 8px;">{{ getNome(item) }}</span>
                        </template>

                    </base-form-field-api-select>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.DURATA"
                                       :items="['STATICO', 'SINGOLO', 'DURATA']"
                                       label="Durata"
                                       placeholder="Seleziona una durata"
                                       hint="La durata determina il giorno in cui compare l'evento"
                                       :disabled="!!isGlobalLoading"
                                       prepend-icon="fa-history"
                                       :rules="[v => !!v || 'La durata è obbligatoria!']"
                    ></base-select-field>
                </h-col>

                <h-col v-if="model.DURATA !== 'STATICO'" class="px-0 pb-0" cols="9">
                    <h-row>

                        <h-col cols="7" class="px-0">
                            <base-data-field v-model="model.dayInizio"
                                             :label="'Giorno' + (model.DURATA === 'DURATA' ? ' Inizio' : '')"
                                             :hint="'Seleziona il giorno' + (model.DURATA === 'DURATA' ? ' di inizio' : '')"
                                             :disabled="!!isGlobalLoading"
                                             prepend-icon="fa-clock"
                                             :rules="[v => !!v || ('La data ' + (model.DURATA === 'DURATA' ? ' di inizio' : '') + 'è obbligatoria!')]"
                                             @input="form.validate();"
                            ></base-data-field>
                        </h-col>

                        <h-col cols="5" class="px-0">
                            <base-data-field v-model="model.timeInizio"
                                             is-time
                                             :label="'Orario' + (model.DURATA === 'DURATA' ? ' Inizio' : '')"
                                             :hint="'Seleziona l\'orario' + (model.DURATA === 'DURATA' ? ' di inizio' : '')"
                                             :disabled="!!isGlobalLoading"
                                             :rules="[v => !!v || ('L\'orario ' + (model.DURATA === 'DURATA' ? ' di inizio' : '') + 'è obbligatoria!')]"
                                             @input="form.validate();"
                            ></base-data-field>
                        </h-col>

                    </h-row>
                </h-col>

                <h-col v-if="model.DURATA === 'DURATA'" class="px-0 pt-0" cols="9">
                    <h-row>

                        <h-col cols="7" class="px-0">
                            <base-data-field v-model="model.dayFine"
                                             :label="'Giorno' + (model.DURATA === 'DURATA' ? ' Fine' : '')"
                                             :hint="'Seleziona il giorno' + (model.DURATA === 'DURATA' ? ' di fine' : '')"
                                             :disabled="!!isGlobalLoading"
                                             prepend-icon="fa-clock"
                                             :rules="[v => !!v || ('La data ' + (model.DURATA === 'DURATA' ? ' di fine' : '') + 'è obbligatoria!'),
                                                      v => v > model.dayInizio || 'La data di fine non può precedere l\'inizio']"
                                             :allowed-dates="v => v > model.dayInizio"
                                             @input="form.validate();"
                            ></base-data-field>
                        </h-col>

                        <h-col cols="5" class="px-0">
                            <base-data-field v-model="model.timeFine"
                                             is-time
                                             :label="'Orario' + (model.DURATA === 'DURATA' ? ' Fine' : '')"
                                             :hint="'Seleziona l\'orario' + (model.DURATA === 'DURATA' ? ' di fine' : '')"
                                             :disabled="!!isGlobalLoading"
                                             :rules="[v => !!v || ('L\'orario ' + (model.DURATA === 'DURATA' ? ' di fine' : '') + 'è obbligatoria!')]"
                                             @input="form.validate();"
                            ></base-data-field>
                        </h-col>

                    </h-row>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.PERMANENZA"
                                     label="Permanenza (min)"
                                     placeholder="Inserisci i minuti di tempo stimato"
                                     hint="La permanenza non è obbligatoria, ma può essere utile per un itinerario"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !v || /^\d+$/.test(v) || 'Il valore deve essere un numero positivo!']"
                                     prepend-icon="fa-hourglass-start"
                    ></base-text-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-form-field-api-autocomplete models-name="citta"
                                                      v-model="model.citta"
                                                      label="Citta"
                                                      item-text="ID_REC"
                                                      placeholder="Digita per iniziare la ricerca"
                                                      hint="La citta è il luogo in cui si svelge l'evento"
                                                      disable-disabled
                                                      prepend-icon="fa-university"
                                                      :rules="[v => !!v || 'La città è obbligatoria!']"
                                                      :auto-start="!!model.ID_CITTA_FK"
                                                      filter-start="DENOMINAZIONE"
                                                      @finish="isLoadingCitta = false;"
                                                      @input="model.ID_CITTA_FK = !!model.citta ? model.citta.ID_REC : null"
                    >
                        <template #item="{ item }">
                            {{ getDenominazione(item) }} {{ item.SIGLA }}
                        </template>
                        <template #selection="{ item }">
                            {{ getDenominazione(item) }} {{ item.SIGLA }}
                        </template>
                    </base-form-field-api-autocomplete>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.INDIRIZZO"
                                     label="Indirizzo"
                                     placeholder="Digita la via e il numero civico"
                                     hint="Utilizza la lente per verificare l'esattezza con Maps"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     prepend-icon="fa-map-marker-alt"
                                     append-icon="fa-search-location"
                                     :rules="[v => !!v || 'L\'indirizzo è obbligatorio!']"
                                     @appendClick="onMapSearch(model.INDIRIZZO)"
                    ></base-text-field>
                </h-col>

                <h-col v-if="!model.IS_PERCORSO" class="px-0" cols="12" sm="9">
                    <base-text-field v-model.trim="model.COORDINATE"
                                     label="Coordinate"
                                     placeholder="Digita le coordinate in formato [xx.x, xx.x]"
                                     hint="Utilizza la lente per verificare l'esattezza con Maps"
                                     :disabled="!!isGlobalLoading"
                                     prepend-icon="fa-map-marked"
                                     append-icon="fa-search-location"
                                     :rules="[v => !!v || 'Le coordinate sono obbligatorie!',
                                              v => $store.getters.coordinateRegExp.test(v) || 'Il formato non è corretto [xx.xxxxxx, xx.xxxxxx]']"
                                     @appendClick="onMapSearch(model.COORDINATE)"
                    ></base-text-field>
                </h-col>

                <h-col v-if="!!model.IS_PERCORSO && !!$store.state.authModule.utente.CAN_EDIT_EVENTO_GPX"
                       class="px-0"
                       cols="9"
                >
                    <base-file-field v-model="model.GPX_FILE"
                                     label="Gpx"
                                     placeholder="Inserisci file Gpx"
                                     hint="Dimensioni massime consentite 2MB"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!model.GPX || !!v || 'Il file gpx è obbligatorio!',
                                              v => !v || v.size <= 2000000 || 'Le dimensioni non possono superare i 2MB!']"
                                     accept=".gpx"
                                     prepend-icon="fa-route"
                                     @input="f => onChangeFileGpx(model, f)"
                    ></base-file-field>
                </h-col>

                <h-col v-if="!!model.IS_PERCORSO && !!$store.state.authModule.utente.CAN_EDIT_EVENTO_GPX"
                       class="px-0"
                       cols="9"
                >
                    <h-row justify="center">
                        <h-col cols="auto" no-padding>

                            <v-icon v-if="!model.GPX" style="font-size: 100px;">fa fa-map-location-dot</v-icon>

                            <h-row v-if="!!model.GPX" style="max-width: 400px;" no-padding>

                                <h-col cols="2" no-padding>
                                    <button-tooltip block
                                                    icon-name="fa-minus"
                                                    color="primary"
                                                    min-height="57"
                                                    :disabled="!!isGlobalLoading"
                                                    tooltip="Click per diminuire lo zoom"
                                                    @click.stop="model.ZOOM = Number(model.ZOOM) - 0.5"
                                    ></button-tooltip>
                                </h-col>

                                <h-col cols="8" no-padding>
                                    <base-text-field v-model.trim="model.ZOOM"
                                                     label="Zoom"
                                                     placeholder="Inserisci lo zoom"
                                                     :disabled="!!isGlobalLoading"
                                                     readonly
                                    ></base-text-field>
                                </h-col>

                                <h-col cols="2" no-padding>
                                    <button-tooltip block
                                                    icon-name="fa-plus"
                                                    color="primary"
                                                    min-height="57"
                                                    :disabled="!!isGlobalLoading"
                                                    tooltip="Click per aumentare lo zoom"
                                                    @click.stop="model.ZOOM = Number(model.ZOOM) + 0.5"
                                    ></button-tooltip>
                                </h-col>

                            </h-row>

                            <GmapMap v-if="!!model.GPX"
                                     :center="{lat: Number(model.GPX[0].lat), lng: Number(model.GPX[0].lon)}"
                                     :zoom="Number(model.ZOOM)"
                                     style="max-width: 400px; height: 200px"
                                     :options="{
                                       disableDefaultUI:true,
                                       draggable: false,
                                       scrollwheel: false
                                     }"
                            >

                                <gmap-polyline :options="{ strokeColor:'#FF9800FF'}"
                                               :path="google && model.GPX.map(c => new google.maps.LatLng(c.lat, c.lon))"
                                />

                                <GmapMarker
                                    :position="google && new google.maps.LatLng(Number(model.LATITUDINE), Number(model.LONGITUDINE))"
                                    :clickable="false"
                                    :draggable="false"
                                />

                            </GmapMap>

                        </h-col>
                    </h-row>
                </h-col>

            </h-row>
        </template>

        <template #testi="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">
                    <v-expansion-panels v-model="panelTxt">
                        <v-expansion-panel v-for="(l, index) in lingue" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col cols="8">
                                        <span>{{ l.NOME }}</span>
                                    </h-col>
                                    <h-col cols="4">
                                        <i v-if="l.IS_DEFAULT">(OBBLIGATORIO)</i>
                                        <i v-else-if="!getTesto(model, l).TITOLO">(VUOTO)</i>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col class="mb-4" cols="12" md="7" no-padding>
                                        <base-text-field v-model.trim="getTesto(model, l).TITOLO"
                                                         label="Titolo"
                                                         placeholder="Digita il titolo dell'evento"
                                                         hint="Il titolo deve essere il riferimento dell'evento"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !getTesto(model, l).LNG_IS_DEFAULT || !!v || 'Il titolo nella lingua ' + l.NOME + ' è obbligatorio!']"
                                                         prepend-icon="fa-signature"
                                                         @input="form.validate();"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" md="5" no-padding>
                                        <base-text-field v-model.trim="getTesto(model, l).DETTAGLIO"
                                                         label="Dettaglio"
                                                         placeholder="Specifica"
                                                         hint="Sub-Categoria"
                                                         maxlength="10"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !getTesto(model, l).LNG_IS_DEFAULT && !getTesto(model, l).TITOLO || !!v || 'Il dettaglio nella lingua ' + l.NOME + ' è obbligatorio!']"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-area-field v-model.trim="getTesto(model, l).TESTO"
                                                              label="Testo"
                                                              placeholder="Il testo dell'evento con tutti i particolari da vedere"
                                                              hint="Il testo deve caratterizzare l'evento"
                                                              maxlength="2000"
                                                              :disabled="!!isGlobalLoading"
                                                              prepend-icon="fa-clipboard"
                                                              :rules="[v => !getTesto(model, l).LNG_IS_DEFAULT && !getTesto(model, l).TITOLO || !!v || 'la descrizione nella lingua ' + l.NOME + ' è obbligatorio!']"
                                        ></base-text-area-field>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </h-col>

            </h-row>
        </template>

        <template #galleria="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">

                    <button-tooltip block
                                    color="warning"
                                    text="AGGIUNGI GALLERIA"
                                    tooltip="Click per aggiungere una nuova galleria"
                                    @click.stop="model.galleria.push({IS_VIDEO:false,IS_COVER:false,IS_LAVORAZIONE:false,IS_ON_TOP:false}); panelGallery = model.galleria.length-1"
                    ></button-tooltip>

                    <v-expansion-panels v-model="panelGallery">
                        <v-expansion-panel v-for="(g, index) in model.galleria" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col cols="8">
                                        <span>{{ !g.IS_COVER ? g.NOME : 'COVER' }}</span>
                                    </h-col>
                                    <h-col cols="4">
                                        <i v-if="g.IS_COVER">(OBBLIGATORIO)</i>
                                        <i v-else-if="!g.NOME">(VUOTO)</i>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col v-show="model.IS_PERCORSO && g.IS_COVER" class="pb-4" cols="12" no-padding>
                                        <button-tooltip block
                                                        color="warning"
                                                        text="CONVERTI IN GALLERIA NORMALE"
                                                        :tooltip="'Click per trasformare in un normale media la galleria ' + g.NOME"
                                                        @click.stop="g.IS_COVER = false"
                                        ></button-tooltip>
                                    </h-col>

                                    <h-col v-show="model.IS_PERCORSO || !g.IS_COVER" class="mb-6 pb-4" cols="12" no-padding>
                                        <button-tooltip block
                                                        color="error"
                                                        text="ELIMINA"
                                                        :tooltip="'Click per eliminare la galleria ' + g.NOME"
                                                        @click.stop="model.galleria = model.galleria.filter((r, i) => i !== index ); panelGallery = null"
                                        ></button-tooltip>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-select-field v-model="g.IS_LAVORAZIONE"
                                                           :items="[ {label: 'DEFINITIVA', value: false}, {label: 'IN LAVORAZIONE', value: true} ]"
                                                           item-text="label"
                                                           item-value="value"
                                                           label="Lavorazione"
                                                           placeholder="Seleziona se la galleria è in lavorazione"
                                                           hint="Se è presente anche solo una galleria in lavorazione l'evento non sarà attivo"
                                                           :disabled="!!isGlobalLoading"
                                                           prepend-icon="fas fa-wrench"
                                                           @input="form.validate();"
                                        ></base-select-field>
                                    </h-col>

                                    <h-col v-show="!g.IS_COVER" class="mb-4" cols="12" no-padding>
                                        <base-select-field v-model="g.IS_ON_TOP"
                                                           :items="[ {label: 'NORMALE', value: false}, {label: 'RIMPIAZZA COVER', value: true} ]"
                                                           item-text="label"
                                                           item-value="value"
                                                           label="Rimpiazza Cover"
                                                           placeholder="Seleziona se la galleria rimpiazzerà la cover in alto"
                                                           hint="Se sono presenti più gallerie che rimpiazzano la cover, verrà presa in considerazione solo la prima"
                                                           :disabled="!!isGlobalLoading"
                                                           prepend-icon="fa fa-copy"
                                                           @input="form.validate();"
                                        ></base-select-field>
                                    </h-col>

                                    <h-col v-show="!g.IS_COVER" class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="g.NOME"
                                                         label="Nome"
                                                         placeholder="Digita il nome della galleria"
                                                         hint="I caratteri speciali e gli spazi vuoti verranno tolti al salvataggio"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading || (!g.MEDIA_FILE && !g.ID_REC)"
                                                         :rules="[v => !!v || 'Il nome della galleria ' + (index+1) + ' è obbligatorio!']"
                                                         prepend-icon="fa-signature"
                                                         @input="form.validate();"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-select-field v-model="g.IS_VIDEO"
                                                           :items="[ {label: 'IMG', value: false}, {label: 'VIDEO', value: true} ]"
                                                           item-text="label"
                                                           item-value="value"
                                                           label="Type"
                                                           placeholder="Seleziona il tipo di galleria"
                                                           hint="Il tipo di galleria se immagine o video"
                                                           :disabled="!!isGlobalLoading"
                                                           prepend-icon="fa-photo-video"
                                                           @input="g.ID_REC = null;g.MEDIA = null;g.MEDIA_FILE = null;g.COVER = null;g.COVER_FILE = null;form.validate();g.reset=!g.reset;"
                                        ></base-select-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-file-field v-model="g.MEDIA_FILE"
                                                         label="Media"
                                                         :placeholder="'Inserisci ' + (g.IS_VIDEO ? 'il video' : 'l\'immagine')"
                                                         :hint="'Dimensioni massime consentite '+ (!g.IS_VIDEO ? 2 : 20) +'MB'"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!g.ID_REC || !!v || 'Il media della galleria ' + (index+1) + ' è obbligatorio!',
                                                                  v => !v || v.size <= (!g.IS_VIDEO ? 2000000 : 20000000) || 'Le dimensioni della galleria ' + (index+1) + ' sono troppo grandi!']"
                                                         :accept="g.IS_VIDEO ? '.mp4' : 'image/*'"
                                                         :prepend-icon="g.IS_VIDEO ? 'fa-video' : 'fa-image'"
                                                         @input="f => {onChangeFileGalleria(f, g, 'MEDIA'); form.validate();}"
                                        ></base-file-field>
                                    </h-col>

                                    <h-col v-show="g.IS_VIDEO" class="mb-4" cols="12" no-padding>
                                        <base-file-field v-model="g.COVER_FILE"
                                                         label="Cover FILE"
                                                         placeholder="Inserisci la cover del video"
                                                         hint="Dimensioni massime consentite 2MB"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!g.ID_REC || !g.IS_VIDEO || !!v || 'La cover della galleria ' + (index+1) + ' è obbligatoria!',
                                                                  v => !v || v.size <= 2000000 || 'Le dimensioni della galleria ' + (index+1) + ' NON possono superare i 2MB!']"
                                                         accept="image/*"
                                                         prepend-icon="fa-book"
                                                         @input="f => {onChangeFileGalleria(f, g, 'COVER'); form.validate();}"
                                        ></base-file-field>
                                    </h-col>

                                    <h-col v-if="!!model.IS_PERCORSO" class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="g.COORDINATE"
                                                         label="Coordinate"
                                                         placeholder="Digita le coordinate in formato [xx.x, xx.x]"
                                                         hint="Utilizza la lente per verificare l'esattezza con Maps"
                                                         :disabled="!!isGlobalLoading"
                                                         prepend-icon="fa-map-marked"
                                                         append-icon="fa-search-location"
                                                         :rules="[v => !!v || 'Le coordinate della galleria ' + (index+1) + ' sono obbligatorie!',
                                                                  v => $store.getters.coordinateRegExp.test(v) || 'Il formato delle coordinate della galleria ' + (index+1) + ' non è corretto [xx.xxxxxx, xx.xxxxxx]']"
                                                         @appendClick="onMapSearch(g.COORDINATE)"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col v-show="!g.IS_COVER" class="mb-4" cols="12" no-padding>
                                        <base-select-field v-model="g.ID_LINGUA_FK"
                                                           :items="lingue"
                                                           item-text="NOME"
                                                           item-value="ID_REC"
                                                           label="Lingua"
                                                           placeholder="Seleziona una lingua"
                                                           hint="La lingua non è obbligatoria, ma definisce su che lingua dovrà comparire la galleria"
                                                           clearable
                                                           :disabled="!!isGlobalLoading"
                                                           prepend-icon="fa-flag"
                                                           @input="form.validate();"
                                        ></base-select-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <h-row justify="center">
                                            <h-col cols="auto">
                                                <base-galleria-evento :galleria="g"
                                                                      :working="!g.ID_REC && !g.MEDIA_FILE && (!g.IS_VIDEO || !g.COVER_FILE)"
                                                                      :reset="g.reset"
                                                ></base-galleria-evento>
                                            </h-col>
                                        </h-row>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </h-col>

            </h-row>
        </template>

        <template #links="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">

                    <button-tooltip block
                                    color="warning"
                                    text="AGGIUNGI LINK"
                                    tooltip="Click per aggiungere un nuovo link"
                                    @click.stop="model.links.push({NOME:'', URI:''}); panelLink = model.links.length-1"
                    ></button-tooltip>

                    <v-expansion-panels v-model="panelLink">
                        <v-expansion-panel v-for="(l, index) in model.links" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col cols="8">
                                        <span>{{ l.NOME }}</span>
                                    </h-col>
                                    <h-col cols="4">
                                        <i v-if="!l.NOME">(VUOTO)</i>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col class="mb-6 pb-4" cols="12" no-padding>
                                        <button-tooltip block
                                                        color="error"
                                                        text="ELIMINA"
                                                        :tooltip="'Click per eliminare il link ' + l.NOME"
                                                        @click.stop="model.links = model.links.filter((r, i) => i !== index ); panelLink = null"
                                        ></button-tooltip>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="l.NOME"
                                                         label="Nome"
                                                         placeholder="Digita il nome del link"
                                                         hint="Il nome verrà visualizzato nel dettaglio dell'evento dell'app"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!v || 'Il nome del link ' + (index+1) + ' è obbligatorio!']"
                                                         prepend-icon="fa-signature"
                                                         @input="form.validate();"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="l.URI"
                                                         label="URL"
                                                         placeholder="Digita l'url del link"
                                                         hint="I link di un evento non sono obbligatori"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!v || 'L\'indirizzo url del link ' + (index+1) + ' è obbligatorio!',
                                                                  v => !v || /^http:\/\/|^https:\/\/|^applinks:\/\//.test(v) || 'L\'indirizzo url del link ' + (index+1) + ' deve essere un link valido!']"
                                                         prepend-icon="fa-link"
                                                         append-icon="fa-search-location"
                                                         @appendClick="onOpenUrl(l.URI)"
                                                         @input="!l.NOME ? l.NOME = l.URI : null"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col v-if="!!model.IS_PERCORSO" class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="l.COORDINATE"
                                                         label="Coordinate"
                                                         placeholder="Digita le coordinate in formato [xx.x, xx.x]"
                                                         hint="Utilizza la lente per verificare l'esattezza con Maps"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!v || 'Le coordinate del link ' + (index+1) + ' sono obbligatorie!',
                                                                  v => $store.getters.coordinateRegExp.test(v) || 'Il formato delle coordinate del link ' + (index+1) + ' non è corretto [xx.xxxxxx, xx.xxxxxx]']"
                                                         prepend-icon="fa-map-marked"
                                                         append-icon="fa-search-location"
                                                         @appendClick="onMapSearch(l.COORDINATE)"
                                        ></base-text-field>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </h-col>

            </h-row>
        </template>

    </base-form>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseForm from '@/components/aaaProject/form/BaseForm';
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';
    import BaseFormFieldApiAutocomplete from '@/components/aaaProject/form/BaseFormFieldApiAutocomplete';
    import BaseSelectLingue from '@/components/aaaProject/table/api/BaseSelectLingue';
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';
    import BaseTextAreaField from '@/components/aaaGenerics/form/BaseTextAreaField';
    import BaseDataField from '@/components/aaaGenerics/form/BaseDataField';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import BaseFileField from '@/components/aaaGenerics/form/BaseFileField';
    import BaseGalleriaEvento from '@/components/secure/eventi/components/BaseGalleriaEvento';
    import {gmapApi} from 'vue2-google-maps'
    import moment from '@/moment';
    import gpx from '@/gpx';

    export default {
        name: 'FormEvento',
        components: {
            HRow,
            HCol,
            BaseForm,
            BaseFormFieldApiSelect,
            BaseFormFieldApiAutocomplete,
            BaseSelectLingue,
            BaseSelectField,
            BaseTextField,
            BaseTextAreaField,
            BaseDataField,
            ButtonTooltip,
            BaseFileField,
            BaseGalleriaEvento
        },
        data: (_this) => ({
            isLoadingWorkspace: !!_this.$store.getters.isSystem,
            isLingueLoading: !!_this.$store.state.authModule.utente.CAN_EDIT_EVENTO_TESTI || !!_this.$store.state.authModule.utente.CAN_EDIT_EVENTO_GALLERY,
            isLoadingCliente: !!_this.$store.state.authModule.utente.CAN_CLIENTE && !_this.$store.getters.isFromCliente,
            isLoadingTipologia: true,
            isLoadingCitta: false,
            panelTxt: null,
            panelGallery: 0,
            panelLink: null,
            tabs: [
                {name: 'info', title: 'INFO DI BASE', icon: 'fa-cube', showIf: true},
                {
                    name: 'testi',
                    title: 'TESTI',
                    icon: 'fa-signature',
                    showIf: !!_this.$store.state.authModule.utente.CAN_EDIT_EVENTO_TESTI
                },
                {
                    name: 'galleria',
                    title: 'GALLERIA',
                    icon: 'fa-photo-video',
                    showIf: !!_this.$store.state.authModule.utente.CAN_EDIT_EVENTO_GALLERY
                },
                {
                    name: 'links',
                    title: 'LINKS',
                    icon: 'fa-link',
                    showIf: !!_this.$store.state.authModule.utente.CAN_EDIT_EVENTO_LINKS
                }
            ],
            newModel: {
                TAGLIA: 'XS',
                DURATA: 'STATICO',
                ZOOM: 10,
                dayInizio: moment.toDate(new Date(), 'yyyy-MM-DD'),
                timeInizio: moment.toDate(new Date(), 'HH:mm:ss'),
                dayFine: moment.toDate(new Date(new Date().setDate(new Date().getDate() + 1)), 'yyyy-MM-DD'),
                timeFine: moment.toDate(new Date(new Date().setDate(new Date().getDate() + 1)), 'HH:mm:ss'),
                DISATTIVAZIONE_MANUALE: !_this.$store.state.authModule.utente.CAN_ENABLE_EVENTO,
                IS_SUGGERITO: false,
                IS_INVISIBILE: false,
                testi: [],
                galleria: [{NOME: 'COVER', IS_VIDEO: false, IS_COVER: true, IS_LAVORAZIONE: false, IS_ON_TOP: false}],
                links: []
            }
        }),
        computed: {
            google: gmapApi,
            lingue: function () {
                return this.$store.state.apiModule.lingue;
            }
        },
        methods: {
            endLoadModel(model) {

                this.isLoadingCitta = !!model.ID_CITTA_FK;

                model.citta = {ID_REC: model.ID_CITTA_FK, SIGLA: model.CT_SIGLA, testi: model.citta || []};

                if (model.DURATA !== 'STATICO') {

                    model.dayInizio = moment.toDate(model.DATA_INIZIO, 'yyyy-MM-DD');
                    model.timeInizio = moment.toDate(model.DATA_INIZIO, 'HH:mm:ss');

                    model.dayFine = moment.toDate(model.DATA_FINE, 'yyyy-MM-DD');
                    model.timeFine = moment.toDate(model.DATA_FINE, 'HH:mm:ss');

                }

                if (!model.ZOOM) {
                    model.ZOOM = 10;
                }

                if (!model.ID_COVER_FK && !model.IS_PERCORSO) {
                    model.galleria.unshift(
                        {NOME: 'COVER', IS_VIDEO: false, IS_COVER: true, IS_LAVORAZIONE: false, IS_ON_TOP: false});
                }

                const testi = model.testi;
                model.testi = [];
                for (const t of testi) {
                    model.testi.push({...t});
                }

                const gallery = model.galleria;
                model.galleria = [];
                for (const g of gallery) {
                    model.galleria.push({...g});
                }

                const links = model.links;
                model.links = [];
                for (const l of links) {
                    model.links.push({...l});
                }

            },
            onSave({model, save}) {

                if (!this.$store.state.authModule.utente.CAN_CLIENTE) {
                    model.ID_CLIENTE_FK = undefined;
                }

                if (!this.$store.state.authModule.utente.CAN_EDIT_EVENTO_TESTI) {
                    model.testi = undefined;
                } else {
                    model.testi = model.testi.filter(t => !!t.TITOLO);
                }

                if (!this.$store.state.authModule.utente.CAN_EDIT_EVENTO_GALLERY) {
                    model.galleria = undefined;
                }

                if (!this.$store.state.authModule.utente.CAN_EDIT_EVENTO_LINKS) {
                    model.links = undefined;
                }

                if (!this.$store.state.authModule.utente.CAN_EDIT_EVENTO_GPX) {
                    model.ZOOM = undefined;
                    model.GPX = undefined;
                }

                model.IS_PERCORSO = !!model.IS_PERCORSO;
                if (!model.IS_PERCORSO) {
                    model.GPX = undefined;
                }

                if (model.DURATA === 'STATICO') {

                    model.DATA_INIZIO = undefined;
                    model.DATA_FINE = undefined;

                } else {

                    model.DATA_INIZIO = moment.toISO(model.dayInizio + model.timeInizio, 'yyyy-MM-DDHH:mm:ss');

                    if (model.DURATA === 'SINGOLO') {
                        model.dayFine = model.dayInizio;
                        model.timeFine = '23:59:59';
                    }

                    model.DATA_FINE = moment.toISO(model.dayFine + model.timeFine, 'yyyy-MM-DDHH:mm:ss');

                }

                if (model.IS_PERCORSO) {

                    if (model.galleria) {
                        for (const g of model.galleria) {
                            g.LATITUDINE = g.COORDINATE.split(', ')[0];
                            g.LONGITUDINE = g.COORDINATE.split(', ')[1];
                        }
                    }

                    if (model.links) {
                        for (const l of model.links) {
                            l.LATITUDINE = l.COORDINATE.split(', ')[0];
                            l.LONGITUDINE = l.COORDINATE.split(', ')[1];
                        }
                    }

                }

                if (model.COORDINATE) {
                    model.LATITUDINE = model.COORDINATE.split(', ')[0];
                    model.LONGITUDINE = model.COORDINATE.split(', ')[1];
                }

                save(model);

            },
            getTesto(model, l) {

                let testo = model.testi.find(t => t.ID_LINGUA_FK === l.ID_REC);

                if (!testo) {
                    model.testi.push({ID_LINGUA_FK: l.ID_REC, LNG_IS_DEFAULT: l.IS_DEFAULT});
                    testo = this.getTesto(model, l);
                }

                return testo;

            },
            getNome(tipologia) {

                let testo = tipologia.testi.find(t => t.ID_LINGUA_FK === this.$store.state.authModule.utente.ID_LINGUA_FK);

                if (!testo) {
                    testo = tipologia.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = tipologia.testi[0];
                }

                return testo.NOME + ' (' + testo.LNG_SIGLA + ')';

            },
            getDenominazione(citta) {

                let testo = citta.testi.find(t => t.ID_LINGUA_FK === this.$store.state.authModule.utente.ID_LINGUA_FK);

                if (!testo) {
                    testo = citta.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = citta.testi[0];
                }

                return testo.DENOMINAZIONE;

            },
            getTipologia(model) {
                return this.$store.state.apiModule.tipologie.find(t => t.ID_REC === model.ID_TIPOLOGIA_FK);
            },
            getClienteTaglie(cliente) {

                let label = cliente.NOME + ' [';

                for (const t of ['XS', 'S', 'M', 'L', 'XL', 'XXL']) {
                    label += ' ' + t + '(' + cliente['EV_' + t + '_COUNT'] + '/' + cliente['CLC_' + t + '_COUNT'] + ') ';
                }

                return label + ']';

            },
            onMapSearch(search) {
                window.open('https://www.google.com/maps/search/' + (search ? search : ''));
            },
            onChangeFileGalleria(f, g, key) {

                if (!f || f.size > 20000000) {
                    return;
                }

                g.reset = !g.reset;

                if (key === 'MEDIA' && !g.IS_COVER && !g.ID_REC) {
                    g.NOME = f.name.replace(this.$store.getters.estensioneRegExp, '');
                }

                this.$store.dispatch('activateGlobalLoading');

                const reader = new FileReader();
                reader.onload = () => {

                    g[key] = reader.result.replace(this.$store.getters.base64RegExp, '');

                    this.$store.dispatch('deactivateGlobalLoading');

                }
                reader.readAsDataURL(f);

            },
            onOpenUrl(search) {
                window.open(search);
            },
            onChangeFileGpx(model, f) {

                model.GPX = null;

                if (!f || f.size > 20000000) {
                    return;
                }

                this.$store.dispatch('activateGlobalLoading');

                const reader = new FileReader();
                reader.onload = () => {

                    gpx.parse(reader.result)
                       .then(
                           coord => {

                               if (!coord) {

                                   this.$store.dispatch(
                                       'activeSnackbar',
                                       {text: 'Il file non risulta un gpx valido', color: 'error'}
                                   );

                                   model.GPX = null;
                                   model.GPX_FILE = null;

                               }

                               model.GPX = coord;

                               if (!model.ZOOM || model.ZOOM === '0.0') {
                                   model.ZOOM = 10.0;
                               }

                           },
                           e => console.log('error: ' + e)
                       )
                       .catch(e => console.log('catch: ' + e))
                       .finally(() => this.$store.dispatch('deactivateGlobalLoading'))

                }
                reader.readAsBinaryString(f);

            }
        }
    }
</script>

<style scoped>

</style>
