import gpxParse from 'gpx-parse/lib/gpx-parse';

export default {

    toJson: function (gpxData) {

        if (!gpxData || !gpxData.tracks) {
            return null;
        }

        const json = [];

        for (const track of gpxData.tracks) {

            for (const segment of track.segments) {

                for (const point of segment) {

                    json.push(
                        {
                            lat: point.lat,
                            lon: point.lon
                        }
                    );

                }

            }

        }

        return json;

    },

    parse: function (textFile) {

        return new Promise(resolve => {
            gpxParse.parseGpx(textFile, (error, data) => resolve(this.toJson(data)));
        });

    }

}
